import React from 'react';

import { useSelector } from '@datagrid/state';
import { Link } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	createStyles,
	TFDataTable,
	TFText,
	Tooltip,
	UnstyledButton,
	useDebouncedValue,
	useLocalStorage,
} from '@tf/ui';
import { D, fmt, S } from '@tf/utils';

import { useOverviewAccounts, useOverviewData } from '@/core/api/overview';
import { appStore } from '@/core/stores';
import { getDisplayName } from '@/core/utils';
import { ReviewState, TFBadge, TFBadgeColors } from '@/components/shared';

import { AssignedManagersWidget } from '../../shared/AssignedManagers';

import { AccountStatus } from './AccountStatus';
import { PriorityButton } from './PriorityButton';

const createComparatorFn = (orderedValues: string[]) => {
	const weights: Record<string, number> = {};
	orderedValues.forEach((value, index) => {
		weights[value] = index + 1;
	});

	return (valueA: string | undefined, valueB: string | undefined) => {
		const weightA = valueA !== undefined ? weights[valueA] : 0;
		const weightB = valueB !== undefined ? weights[valueB] : 0;

		return weightA - weightB;
	};
};

const compareImportance = createComparatorFn(['LOW', 'MEDIUM', 'HIGH']);

const compareAccountStatus = createComparatorFn([
	'PROSPECT',
	'ACTIVE',
	'SUSPENDED',
	'REJECTED',
	'CLOSED',
]);

const riskLevelColors: Record<BackendTypes.OverviewAccountRiskLevel, TFBadgeColors> = {
	VERY_HIGH: TFBadgeColors.RED,
	HIGH: TFBadgeColors.ORANGE,
	MEDIUM: TFBadgeColors.YELLOW,
	LOW: TFBadgeColors.GREEN,
};

const getApplicantReviewStatus = (account: BackendTypes.OverviewAccount) => {
	return account.reviewStates?.find((review) => review.connectionKind === 'APPLICANT')?.state;
};

const compareVerificationStatus = createComparatorFn([
	'DRAFT',
	'READY_TO_REVIEW',
	'UNDER_REVIEW',
	'VERIFIED',
	'UPDATE_REQUIRED',
]);

const useStyles = createStyles(({ colors }) => ({
	accountName: {
		fontSize: 13,
		height: 32,
		display: 'flex',
		alignItems: 'center',
		color: colors.brand[6],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
	accountNameText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: 300,
	},
}));

const filtersNamesMap: Record<string, string> = {
	kind: 'accountType',
	'values.accountStatus': 'accountStatus',
	verification: 'verificationStatus',
	importance: 'priority',
	assignedTo: 'assignedTo',
};

const sortingNamesMap: Record<string, string> = {
	name: 'ACCOUNT_NAME',
	kind: 'ACCOUNT_TYPE',
	'values.accountStatus': 'ACCOUNT_STATUS',
	verification: 'VERIFICATION_STATUS',
	importance: 'PRIORITY',
	'values.nextKycReviewDate': 'KYC_VIEW',
};

export const AccountTable = () => {
	const { classes } = useStyles();
	const accountKinds = useSelector(() => appStore.defs.options['AccountKind'].get());

	const [pagination, setPagination] = useLocalStorage<{ pageSize: number; pageIndex: number }>({
		defaultValue: { pageSize: 10, pageIndex: 0 },
		key: 'accounts-table-pagination',
		getInitialValueInEffect: false,
	});

	const [searchValue, setSearchValue] = useLocalStorage<string>({
		defaultValue: '',
		key: 'accounts-table-search',
		getInitialValueInEffect: false,
	});
	const [debouncedSearch] = useDebouncedValue(searchValue, 500);

	const [filtersState, setFiltersState] = useLocalStorage<
		{
			id: string;
			value: unknown;
		}[]
	>({
		defaultValue: [],
		key: 'account-table-filters',
		getInitialValueInEffect: false,
	});

	const [sortingState, setSortingState] = useLocalStorage<
		{
			desc: boolean;
			id: string;
		}[]
	>({
		defaultValue: [{ desc: true, id: 'ACCOUNT_NAME' }],
		key: `account-table-sorting`,
		getInitialValueInEffect: false,
	});

	const sortingParams =
		sortingState.length > 0
			? {
					sortBy: sortingNamesMap[sortingState[0].id] as BackendTypes.OverviewSortBy | undefined,
					sortOrder: sortingState[0].desc ? 'DESC' : ('ASC' as BackendTypes.OverviewSortOrder),
			  }
			: undefined;

	const filteringParams = filtersState.reduce<Record<string, any>>((acc, curr) => {
		acc[filtersNamesMap[curr.id]] = curr.value;
		return acc;
	}, {});

	const { data } = useOverviewData();
	const listUsers = data?.listUsers?.sort((a, b) => getDisplayName(a).localeCompare(getDisplayName(b)));

	const { data: accounts, isPending } = useOverviewAccounts(
		pagination.pageIndex + 1,
		pagination.pageSize,
		{
			...filteringParams,
			...sortingParams,
			query: debouncedSearch,
		}
	);

	return (
		<TFDataTable<BackendTypes.OverviewAccount>
			name="account-list"
			isLoading={isPending}
			serverSorting={{
				onSortingChange: setSortingState,
				state: sortingState,
			}}
			serverFiltering={{
				onFiltersChange: setFiltersState,
				state: filtersState,
			}}
			serverPagination={
				accounts
					? {
							...pagination,
							rowCount: accounts.count,
							onPaginationChange: setPagination,
					  }
					: undefined
			}
			serverSearch={{
				state: searchValue,
				onSearchChange: setSearchValue,
			}}
			data={accounts?.items ?? []}
			defs={[
				{
					header: 'Account name',
					accessorKey: 'name',
					size: 200,
					Cell: ({ cell }) => {
						const { nodeId, name } = cell.row.original;
						return (
							<UnstyledButton
								component={Link}
								to={`/accounts/${nodeId}`}
								className={classes.accountName}
							>
								<Tooltip label={name || 'N/A'} position="top">
									<TFText className={classes.accountNameText}>{name || 'N/A'}</TFText>
								</Tooltip>
							</UnstyledButton>
						);
					},
				},
				{
					header: 'Account type',
					accessorKey: 'kind',
					Cell: ({ cell }) => {
						const nextKind = accountKinds?.find((a: { value: string }) => a.value === cell.getValue());
						return <TFText lineClamp={1}>{nextKind?.label || 'Not defined'}</TFText>;
					},
				},
				{
					header: 'Account status',
					id: 'values.accountStatus',
					accessorKey: 'values.accountStatus',
					filterFn: (row: any, id: string, value: string) => {
						const accountStatus = row.getValue(id);
						if (accountStatus) return accountStatus === value;
						else return false;
					},
					sortUndefined: 1, // lower priority
					sortingFn: (
						rowA: { original: BackendTypes.OverviewAccount },
						rowB: {
							original: BackendTypes.OverviewAccount;
						}
					) => {
						return compareAccountStatus(
							rowA.original.values.accountStatus,
							rowB.original.values.accountStatus
						);
					},
					Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
						return <AccountStatus accountStatus={row.original.values?.accountStatus} />;
					},
				},
				{
					header: 'Verification status',
					accessorKey: 'verification',
					filterFn: (row: any, id: string, value: string) => {
						return getApplicantReviewStatus(row.original) === value;
					},
					sortingFn: (
						rowA: { original: BackendTypes.OverviewAccount },
						rowB: {
							original: BackendTypes.OverviewAccount;
						}
					) => {
						return compareVerificationStatus(
							getApplicantReviewStatus(rowA.original),
							getApplicantReviewStatus(rowB.original)
						);
					},
					Cell: ({ cell }) => <ReviewState account={cell.row.original} />,
				},
				{
					header: 'Assigned managers',
					accessorKey: 'assignedManagers',
					filterFn: (row: any, id: string, value: string) => {
						return row.original?.assignedManagers?.includes(value);
					},
					enableSorting: false,
					Cell: (data: { row: { original: { nodeId: number; assignedManagers: string[] } } }) => (
						<AssignedManagersWidget
							accountId={data.row.original.nodeId}
							selectedIds={data.row.original.assignedManagers}
							managers={listUsers ?? []}
						/>
					),
				},
				{
					header: 'Priority',
					accessorKey: 'importance',
					sortingFn: (
						rowA: { original: BackendTypes.OverviewAccount },
						rowB: {
							original: BackendTypes.OverviewAccount;
						}
					) => {
						return compareImportance(rowA.original.importance, rowB.original.importance);
					},
					Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => (
						<PriorityButton accountId={row.original.nodeId} value={row.original.importance} />
					),
				},
				{
					header: 'Risk profile',
					accessorKey: 'values.riskLevel',
					enableSorting: false,
					Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
						const riskLevel = row.original.values.riskLevel;
						return (
							<TFBadge
								withBackground={false}
								text={riskLevel ? S.prettify(riskLevel) : 'Not set'}
								color={riskLevel ? riskLevelColors[riskLevel] : TFBadgeColors.DIMMED}
							/>
						);
					},
				},
				{
					header: 'Next KYC review',
					accessorKey: 'values.nextKycReviewDate',
					sortUndefined: 1, // lower priority
					Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
						const date: string | undefined = row.original.values.nextKycReviewDate;
						const status = row.original.values.accountStatus;
						if (!date || status === 'REJECTED' || status === 'CLOSED') {
							return <TFText color="dimmed">Not defined</TFText>;
						}
						const daysOverdue = D.getOverdueDaysCount(date);
						return (
							<TFText color={daysOverdue ? 'red' : 'initial'}>
								{daysOverdue > 0
									? `Overdue ${daysOverdue} ${daysOverdue > 1 ? 'days' : 'day'}`
									: fmt.toDate(date, { preset: 'full_date' })}
							</TFText>
						);
					},
				},
			]}
			availableFilters={[
				{
					name: 'kind',
					label: 'Account type',
					type: 'select',
					value: accountKinds,
				},
				{
					name: 'values.accountStatus',
					label: 'Account status',
					type: 'select',
					value: [
						{ value: 'PROSPECT', label: 'Prospect' },
						{ value: 'ACTIVE', label: 'Active' },
						{ value: 'SUSPENDED', label: 'Suspended' },
						{ value: 'REJECTED', label: 'Rejected' },
						{ value: 'CLOSED', label: 'Closed' },
					],
				},
				{
					name: 'verification',
					type: 'select',
					label: 'Verification status',
					value: [
						{ value: 'DRAFT', label: 'Draft' },
						{ value: 'READY_TO_REVIEW', label: 'Ready to review' },
						{ value: 'UNDER_REVIEW', label: 'Pending review' },
						{ value: 'VERIFIED', label: 'Verified' },
						{ value: 'UPDATE_REQUIRED', label: 'Update required' },
					],
				},
				{
					name: 'importance',
					type: 'select',
					label: 'Priority',
					value: [
						{ value: 'HIGH', label: 'High' },
						{ value: 'MEDIUM', label: 'Medium' },
						{ value: 'LOW', label: 'Low' },
					],
				},
				{
					name: 'assignedTo',
					type: 'select',
					label: 'Assigned manager',
					value: [
						{ value: '__unassigned__', label: 'Not assigned' },
						...(listUsers?.map((user) => ({ value: user.userId, label: getDisplayName(user) })) || []),
					],
				},
			]}
		/>
	);
};
