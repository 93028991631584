import { useSelector } from '@datagrid/state';
import { useParams } from 'react-router-dom';

import { BackendClient } from '@tf/api';
import { Button, Icon, TFNotifier } from '@tf/ui';
import { saveFile } from '@tf/utils';

import { monitoringStore } from '@/core/stores';

export const MonitoringReportButton = () => {
	const entity = useSelector(() => monitoringStore.selected.get());
	const params = useParams<{ graphId: string }>();
	const graphId = parseInt(params.graphId as string, 10);

	const getPdf = async () => {
		try {
			const res = await BackendClient.getInstance().monitoringSearchDetailsPdf(graphId);

			if (!res.ok) {
				TFNotifier.error('Cannot download certificate, please try again later');
				return;
			}

			const blob = await new Response(res.body).arrayBuffer();
			saveFile(blob, `${entity?.name || 'monitoring'}.pdf`);
		} catch (e) {
			TFNotifier.error('Cannot download certificate, please try again later');
		}
	};

	return (
		<Button leftIcon={<Icon.IconFileDownload size={16} />} size="sm" onClick={getPdf}>
			Download Certificate
		</Button>
	);
};
