import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { Box, Grid, TFCard } from '@tf/ui';
import type { FormSegment } from '@tf/utils';

import { useSelectedMonitoringEntity } from '@/core/hooks';
import { getSegmentsQuery } from '@/core/queries';
import { MonitoringCardForm, MonitoringCardMatchesTable } from '@/components/monitoring';
import { FormLoader } from '@/components/shared';

type PathParams = {
	graphId: string;
};

const SEGMENTS = [
	'INDIVIDUAL_ENTITY__DETAILS',
	'INDIVIDUAL_ENTITY__ADDRESSES',
	'CORPORATE_ENTITY__DETAILS',
	'COMMON__BACKGROUND_CHECK',
];

const useSegmentsQuery = (entityId: number, formSegments: FormSegment[]) =>
	useQuery({
		queryKey: ['monitoring-account-info', entityId],
		queryFn: () => getSegmentsQuery(formSegments),
	});

export const MonitoringCardMatches = () => {
	const params = useParams<PathParams>();
	const entityData = useSelectedMonitoringEntity();
	const entityId = parseInt(params.graphId as string, 10);
	const formSegments: FormSegment[] = entityData?.listSegments
		.filter((s: BackendTypes.SegmentInfo) => {
			const segmentKind = s.SegmentIdentity.segmentKind;
			return SEGMENTS.includes(segmentKind);
		})
		.map((s: BackendTypes.SegmentInfo) => ({ ...s.SegmentIdentity, accessMode: 'VIEW' }));
	const segmentsQuery = useSegmentsQuery(entityId, formSegments);

	return (
		<Box p="0.75rem 0">
			<Grid>
				<Grid.Col span={4}>
					<Box mt={12}>
						<TFCard title="Information">
							{segmentsQuery.status === 'success' ? (
								<MonitoringCardForm data={segmentsQuery.data} />
							) : (
								<FormLoader />
							)}
						</TFCard>
					</Box>
				</Grid.Col>
				<Grid.Col span={8}>
					<MonitoringCardMatchesTable />
				</Grid.Col>
			</Grid>
		</Box>
	);
};
