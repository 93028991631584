import { useEffect } from 'react';

import { useObservable } from '@datagrid/state';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { TFNotifier } from '@tf/ui';
import type { FormSegment, FormValues, SubmitHandler } from '@tf/utils';
import { rawRevision } from '@tf/utils';

import { useProcessingScripts } from '@/core/hooks/use-processing-scripts';
import { useSelectedAccount } from '@/core/hooks/use-selected-account';
import { writeSegmentsMutation } from '@/core/mutations';
import { getSegmentsQuery } from '@/core/queries';

interface State {
	values?: FormValues;
	revisions: Record<string, number>;
}

export const useAccountForm = (segmentKinds: string[], options: { onSuccess: () => void }) => {
	const { meta } = useSelectedAccount();

	const state = useObservable<State>({
		revisions: {},
	});

	const formSegments: FormSegment[] = meta.listSegments
		.filter((s) => {
			return segmentKinds.includes(s.SegmentIdentity.segmentKind);
		})
		.map((s) => ({ ...s.SegmentIdentity, accessMode: s.AccessMode }));

	const segmentsQuery = useQuery({
		queryKey: ['account-form', ...segmentKinds],
		queryFn: () => getSegmentsQuery(formSegments),
	});

	useEffect(() => {
		if (segmentsQuery.data) {
			const nextState: Required<State> = {
				values: {},
				revisions: {},
			};
			for (const segmentData of segmentsQuery.data) {
				const { info, segment } = segmentData.container;
				const segmentKind = info.SegmentIdentity.segmentKind;
				nextState.values[segmentKind] = segment;
				nextState.revisions[segmentKind] = info.Revision.revision;
			}
			state.assign(nextState);
		}
	}, [segmentsQuery.data, state]);

	const queryClient = useQueryClient();
	const handleSubmit: SubmitHandler = async ({ values, segmentValidationStatuses }) => {
		const payload: BackendTypes.SegmentsWritePayload = [];
		for (const formSegment of formSegments) {
			if (formSegment.accessMode === 'VIEW') {
				continue;
			}
			payload.push({
				segmentIdentity: formSegment,
				segment: values[formSegment.segmentKind],
				validationStatus: segmentValidationStatuses[formSegment.segmentKind],
				revision: {
					...rawRevision,
					revision: state.revisions.peek()[formSegment.segmentKind],
				},
			});
		}

		const res = await writeSegmentsMutation(payload);

		// Update revisions
		for (const segmentData of res) {
			const { SegmentIdentity, Revision } = segmentData.container.info;
			const segmentKind = SegmentIdentity.segmentKind;
			state.revisions.set((prev) => ({ ...prev, [segmentKind]: Revision.revision }));
		}

		TFNotifier.success('Account updated successfully!');
		await queryClient.refetchQueries({ queryKey: ['accounts', meta.graphNodeId] });
		await queryClient.refetchQueries({ queryKey: ['account-info', meta.graphNodeId] });
		await segmentsQuery.refetch();
		await queryClient.refetchQueries({ queryKey: ['overviewAccount', meta.graphNodeId] });
		options.onSuccess();
	};

	const scripts = useProcessingScripts(segmentKinds);

	return { state, scripts, formSegments, handleSubmit };
};
