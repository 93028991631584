import { queryOptions, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

const accountEventLogsQueryOptions = (accountId: number) => {
	return queryOptions({
		queryKey: ['accountEventsLog', accountId],
		queryFn: async (): Promise<BackendTypes.AccountEventLogData> => {
			const api = BackendClient.getInstance();
			const res = await api.accountEventLog(accountId);
			return res.json();
		},
		staleTime: 20 * 1000,
	});
};

export const useAccountEventsLog = (accountId: number) => {
	return useQuery(accountEventLogsQueryOptions(accountId));
};
