import React from 'react';

import { useSelector } from '@datagrid/state';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { Group } from '@tf/ui';
import { T } from '@tf/utils';

import { useSelectedMonitoringEntity } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { MonitoringCardTabs, MonitoringReportButton } from '@/components/monitoring';
import { MonitoringCardInfoButton } from '@/components/monitoring/MonitoringCard/MonitoringCardInfo';

export const MonitoringCardTabsLayout = () => {
	const [searchParams] = useSearchParams();
	const tenant = useSelector(() => appStore.tenant?.get());
	T.assertNotNullish(tenant, 'no tenant in appStore');

	const params = useParams<{ graphId: string }>();
	const graphId = parseInt(params.graphId as string, 10);

	const data = useSelectedMonitoringEntity();
	return (
		<>
			<TFPageHeader
				sx={() => ({
					marginBottom: 0,
				})}
				title={data.name}
				tenant={tenant}
				breadcrumbs={[
					{ href: '/monitoring', title: 'Monitoring' },
					{ href: `/monitoring/${graphId}?${searchParams.toString()}`, title: data.name },
				]}
				toolbar={
					<Group>
						<MonitoringCardInfoButton />
						<MonitoringReportButton />
					</Group>
				}
			/>
			<MonitoringCardTabs />
			<TFPageContent>
				<Outlet />
			</TFPageContent>
		</>
	);
};
