import React from 'react';

import { useSelector } from '@datagrid/state';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { getFormHash, getFormStatuses, selectForm } from '@tf/shared';
import { S } from '@tf/utils';

import { useAccountParams, useSelectedAccount, useSelectedReview } from '@/core/hooks';
import { getSegmentsQuery } from '@/core/queries';
import { appStore } from '@/core/stores';
import type { ExtendedReviewContainer } from '@/core/types';
import { FormLoader } from '@/components/shared';

import { Form } from './Form';
import { FormWrapper } from './FormWrapper';

const ReviewFormContent: React.FC<{
	form: ExtendedFormDefinition<BackendTypes.FormDefinition>;
	segmentIdentities: BackendTypes.SegmentIdentity[];
	review: ExtendedReviewContainer;
}> = ({ form, segmentIdentities, review }) => {
	// Find segment which needs data from self-segment to work
	// Currently it is only Background check, it needs entity name
	const additionalSegments: BackendTypes.SegmentIdentity[] = [];

	const selfSegments = useSelector(() => appStore.defs.get().selfSegments);
	const selfSegmentKinds = Object.values(selfSegments);
	const isSelfSegment = (segment: BackendTypes.SegmentIdentity) =>
		selfSegmentKinds.includes(segment.segmentKind);

	const segmentWithSelfSegmentDataDependency = form.segmentIdentities.find(
		(s) => s.segmentKind === 'COMMON__BACKGROUND_CHECK'
	);

	if (segmentWithSelfSegmentDataDependency) {
		// Dependent segment lives on the entity, so we can find required self segment
		const requiredSelfSegment = segmentIdentities.find(
			(s) => s.graphId === segmentWithSelfSegmentDataDependency.graphId && isSelfSegment(s)
		);
		if (requiredSelfSegment) {
			additionalSegments.push(requiredSelfSegment);
		}
	}

	const getSelfSegmentData = (segmentsData: BackendTypes.SegmentData[]) => {
		const selfSegment = segmentsData.find(
			(s) =>
				s.container.info.SegmentIdentity.graphId === segmentWithSelfSegmentDataDependency?.graphId &&
				isSelfSegment(s.container.info.SegmentIdentity)
		);

		if (!selfSegment) {
			return;
		}

		const segmentData: any = selfSegment.container.segment;
		if (selfSegment.container.info.SegmentIdentity.segmentKind === 'INDIVIDUAL_ENTITY__SELF') {
			return {
				firstName: segmentData.first_name,
				lastName: segmentData.last_name,
				middleName: segmentData.middle_name,
			} as BackendTypes.AdhocSearchPersonParams;
		}

		return {
			entityName: segmentData.name,
		} as BackendTypes.AdhocSearchEntityParams;
	};

	// * Get initial form data
	const segmentsQuery = useQuery<BackendTypes.SegmentsReadData>({
		queryKey: ['segments', form.name, getFormHash(form)],
		queryFn: () =>
			getSegmentsQuery(
				[...form.segmentIdentities, ...additionalSegments].filter((v, i, a) => {
					// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
					return a.findIndex((v2) => ['segmentKind', 'graphId'].every((k) => v2[k] === v[k])) === i;
				})
			),
		enabled: Boolean(form.segmentIdentities),
	});

	const { structure } = getFormStatuses({
		containers: review.listSegmentReviewContainers,
		segmentIdentities: form.segmentIdentities,
	});
	const hasPrevErrors = structure === 'VALIDATION_ERRORS';

	return (
		<FormWrapper def={form}>
			{segmentsQuery.status === 'success' ? (
				<Form
					key={`${form.name}.${getFormHash(form)}`}
					data={segmentsQuery.data.filter((segment) =>
						form.segmentIdentities.some(
							(item) => item.segmentKind === segment.container.info.SegmentIdentity.segmentKind
						)
					)}
					additionalData={getSelfSegmentData(segmentsQuery.data)}
					validateInitialValues={hasPrevErrors}
				/>
			) : (
				<FormLoader />
			)}
		</FormWrapper>
	);
};

export const ReviewForm = () => {
	const params = useAccountParams();
	const account = useSelectedAccount();
	const review = useSelectedReview();
	const formDefs = useSelector(() => appStore.defs.get().forms);

	// Pick current form
	const segmentIdentities = [
		...review.listEntities.flatMap(({ listSegments }) =>
			listSegments.flatMap(({ SegmentIdentity }) => SegmentIdentity)
		),
		// Segments from connections:
		...review.listEntities.flatMap((entity) => {
			return entity.listConnections.flatMap((connection) => {
				return connection.listFromEntities.flatMap((connectionData) => {
					return connectionData.listSegments.map((s) => s.SegmentIdentity);
				});
			});
		}),
		...review.listSegmentReviewContainers.flatMap(({ identity }) => identity),
		...account.meta.listSegments.map((s) => s.SegmentIdentity),
	];

	// Try to read form and redirect to parent entity in case of failure
	let form: ExtendedFormDefinition<BackendTypes.FormDefinition> | null = null;
	try {
		form = selectForm({
			formDefs,
			segmentIdentities,
			formName: params.formKind,
			hash: params.searchParams.hash,
		});
	} catch (err) {
		// Lower level because we handle this with redirect
		Sentry.captureException(err, { level: 'warning' });
	}

	if (!form) {
		const connectionPath = `/accounts/${params.accountId}/${S.slugify(params.connectionKind)}`;
		return (
			<Navigate
				to={
					params.entityId
						? `${connectionPath}/${params.connectionId}/entity/${params.entityId}`
						: connectionPath
				}
			/>
		);
	}

	return <ReviewFormContent form={form} segmentIdentities={segmentIdentities} review={review} />;
};
