import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';
import { TFNotifier } from '@tf/ui';
import type { FormSegment } from '@tf/utils';

const clientCasesRequestsQueryOptions = (accountId: number) => {
	return queryOptions({
		queryKey: ['clientCasesRequests', accountId],
		queryFn: async (): Promise<BackendTypes.ClientCaseRequest[]> => {
			const api = BackendClient.getInstance();
			const res = await api.listClientCaseRequests(accountId);
			return res.json();
		},
		staleTime: 20 * 1000,
	});
};

export const useClientCasesRequests = (accountId: number) => {
	return useQuery(clientCasesRequestsQueryOptions(accountId));
};

const clientCaseRequestQueryOptions = (accountId: number, requestId: string) => {
	return queryOptions({
		queryKey: ['clientCaseRequest', accountId, requestId],
		queryFn: async (): Promise<BackendTypes.ClientCaseRequest> => {
			const api = BackendClient.getInstance();
			const res = await api.getClientCaseRequest(accountId, requestId);
			return res.json();
		},
		staleTime: 20 * 1000,
	});
};

export const useClientCaseRequest = (accountId: number, requestId: string) => {
	return useQuery(clientCaseRequestQueryOptions(accountId, requestId));
};

type CreateClientCaseRequestParams = {
	clientEmail: string;
	accountId: number;
	requestKind: BackendTypes.ClientCaseRequestKind;
	livenessCheckEntityId?: number;
};
export const useCreateClientCaseRequestMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			accountId,
			clientEmail,
			requestKind,
			livenessCheckEntityId,
		}: CreateClientCaseRequestParams): Promise<BackendTypes.CreateClientCaseRequestData> => {
			const response = await BackendClient.getInstance().createClientCaseRequest(accountId, {
				clientEmail,
				requestKind,
				livenessCheckEntityId,
			});
			return (await response.json()) as BackendTypes.CreateClientCaseRequestData;
		},
		onSuccess: async (_, { accountId }) => {
			return Promise.all([queryClient.invalidateQueries(clientCasesRequestsQueryOptions(accountId))]);
		},
	});
};

type UpdateClientCaseRequestParams = {
	accountId: number;
	requestId: string;
	data: BackendTypes.UpdateClientCaseRequest;
};
export const useUpdateClientCaseRequestMutation = (accountId: number) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			accountId,
			requestId,
			data,
		}: UpdateClientCaseRequestParams): Promise<BackendTypes.UpdateClientCaseRequestData> => {
			const response = await BackendClient.getInstance().updateClientCaseRequest(
				accountId,
				requestId,
				data
			);
			return (await response.json()) as BackendTypes.UpdateClientCaseRequestData;
		},
		onSuccess: async (res) => {
			return Promise.all([
				queryClient.invalidateQueries(clientCaseRequestQueryOptions(accountId, res.uuid)),
				queryClient.invalidateQueries(clientCasesRequestsQueryOptions(accountId)),
			]);
		},
		onError: () => {
			TFNotifier.error('Error while saving changes');
		},
	});
};

export const useRevokeClientCaseRequestMutation = (accountId: number) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({
			accountId,
			requestId,
		}: {
			accountId: number;
			requestId: string;
		}): Promise<BackendTypes.RevokeClientCaseRequestData> => {
			await BackendClient.getInstance().revokeClientCaseRequest(accountId, requestId);
		},
		onSuccess: async (_, { requestId }) => {
			TFNotifier.success('Request revoked');
			return Promise.all([
				queryClient.invalidateQueries(clientCasesRequestsQueryOptions(accountId)),
				queryClient.invalidateQueries(clientCaseRequestQueryOptions(accountId, requestId)),
			]);
		},
		onError: () => {
			TFNotifier.error('Error while revoking request');
		},
	});
};

export const useSendClientCaseRequestEmailMutation = (accountId: number) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ accountId, requestId }: { accountId: number; requestId: string }) => {
			await BackendClient.getInstance().sendClientCaseRequestEmail(accountId, requestId);
		},
		onSuccess: async (_, { requestId }) => {
			TFNotifier.success('Your request has been sent');
			return Promise.all([
				queryClient.invalidateQueries(clientCaseRequestQueryOptions(accountId, requestId)),
				queryClient.invalidateQueries(clientCasesRequestsQueryOptions(accountId)),
			]);
		},
		onError: () => {
			TFNotifier.error('Error while sending email');
		},
	});
};

export const useDownloadClientCaseRequestFileMutation = () => {
	return useMutation({
		mutationFn: async ({
			accountId,
			requestId,
			fileStorageKey,
		}: {
			accountId: number;
			requestId: string;
			fileStorageKey: string;
		}) => {
			const res = await BackendClient.getInstance().downloadClientCaseRequestFile(
				accountId,
				requestId,
				fileStorageKey
			);

			return res.arrayBuffer();
		},

		onError: () => {
			TFNotifier.error('Error while downloading file');
		},
	});
};

export const useCloseClientCaseRequestMutation = (accountId: number) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ requestId }: { requestId: string }) => {
			await BackendClient.getInstance().closeClientCaseRequest(accountId, requestId);
		},
		onSuccess: (_, { requestId }) => {
			TFNotifier.success('Case request closed successfully');
			return Promise.all([
				queryClient.invalidateQueries(clientCasesRequestsQueryOptions(accountId)),
				queryClient.invalidateQueries(clientCaseRequestQueryOptions(accountId, requestId)),
			]);
		},
		onError: () => {
			TFNotifier.error('Error while closing request');
		},
	});
};

const segmentsQueryOptions = (formSegments: FormSegment[]) => {
	return queryOptions({
		queryKey: ['liveness-check', formSegments],
		queryFn: async (): Promise<BackendTypes.SegmentsReadData> => {
			const api = BackendClient.getInstance();
			const res = await api.segmentsRead(formSegments);
			return res.json();
		},
	});
};

export const useSegmentsQuery = (formSegments: FormSegment[]) => {
	return useQuery(segmentsQueryOptions(formSegments));
};
