import { useSelector } from '@datagrid/state';

import { Group, Stack, TFText } from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { monitoringStore } from '@/core/stores';
import { MATCH_STATUSES } from '@/core/types';

interface Item {
	name: string;
	value: string;
}

export const MonitoringCardInfoList = () => {
	const searchInfo = useSelector(() => monitoringStore.searchInfo.get());

	const result: Item[] = [
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		{ name: 'Search Term', value: searchInfo?.searchTerm },
		{
			name: 'Created At',
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			value: searchInfo?.createdAt ? fmt.toDate(searchInfo?.createdAt, { preset: 'full_date' }) : null,
		},
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		{ name: 'Fuzziness Interval', value: searchInfo?.fuzzinessInterval },
		// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		{ name: 'Entity Type', value: searchInfo?.entityType },
		{
			name: 'Match Status',
			value: MATCH_STATUSES.find((item) => item.value === searchInfo?.matchStatus)?.label || 'N/A',
		},
		{ name: 'Risk Level', value: searchInfo?.riskLevel || 'N/A' },
		{
			name: 'Updated At',
			value: searchInfo?.updatedAt ? fmt.toDate(searchInfo?.updatedAt, { preset: 'full_date' }) : null,
		},
	];

	return (
		<Stack>
			{result
				.filter((item) => item.value)
				.map((item: Item) => (
					<Group key={item.name} spacing="xs">
						<TFText weight="500">{S.prettify(item.name)}:</TFText>
						<TFText color="dimmed">{S.prettify(item.value as string)}</TFText>
					</Group>
				))}
		</Stack>
	);
};
