import React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from './AuthProvider';
import { ConfigProvider } from './ConfigProvider';
import { StateProvider } from './StateProvider';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
	},
});

export const AppProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<ConfigProvider>
				<AuthProvider>
					<StateProvider>{children}</StateProvider>
				</AuthProvider>
			</ConfigProvider>
		</QueryClientProvider>
	);
};
