import type { FC } from 'react';

import type { BackendTypes } from '@tf/api';
import { TFText } from '@tf/ui';
import { S } from '@tf/utils';

interface Props {
	accountStatus: BackendTypes.OverviewAccountStatus | undefined;
}

const colors: Record<BackendTypes.OverviewAccountStatus, string> = {
	PROSPECT: 'brand',
	ACTIVE: 'green',
	SUSPENDED: 'orange',
	REJECTED: 'red',
	CLOSED: 'red',
};

export const AccountStatus: FC<Props> = ({ accountStatus }) => {
	return accountStatus ? (
		<TFText color={colors[accountStatus]}>{S.prettify(accountStatus)}</TFText>
	) : (
		<TFText color="dimmed">Not defined</TFText>
	);
};
