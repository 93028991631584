import React, { useEffect, useState } from 'react';

import type { BackendTypes } from '@tf/api';
import { Box, Menu, Modal, useDisclosure } from '@tf/ui';

import { useOverviewAccount } from '@/core/api/overview';
import {
	useAccountInfoSegments,
	useSelectedAccount,
	useSelectedAccountApplicantReview,
} from '@/core/hooks';
import { useSelectedAccountRmData } from '@/core/hooks/use-select-account-RM-data';
import { UserCapabilities } from '@/core/types';
import { getLabel, useCurrentUserCapabilityCheck } from '@/core/utils';
import type { TFBadgeColors } from '@/components/shared';
import { TFBadge } from '@/components/shared';

import { AccountStatusBadge } from './AccountStatusBadge';
import { AccountStatusModal } from './AccountStatusModal';
import { accountStatusBadgeColors } from './constants';
import type { ModalProps } from './types';
import { getAvailableStatuses, getModalData } from './utils';

export const AccountStatus = () => {
	const statusModal = useDisclosure();
	const [potentialStatuses, setPotentialStatuses] = useState<BackendTypes.OverviewAccountStatus[]>([]);
	const [label, setLabel] = useState<string>('');
	const [modalData, setModalData] = useState<ModalProps>();
	const [tempStatus, setTempStatus] = useState<BackendTypes.OverviewAccountStatus | null>(null);
	const [clientAccountStatus, setClientAccountStatus] =
		useState<BackendTypes.OverviewAccountStatus | null>(null);

	const { accountDataMutation } = useAccountInfoSegments();
	const rmData = useSelectedAccountRmData();
	const reviewState = useSelectedAccountApplicantReview();
	const canEditAccountData = useCurrentUserCapabilityCheck(UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA);
	const { meta } = useSelectedAccount();
	const { data } = useOverviewAccount(meta.graphNodeId);

	useEffect(() => {
		if (rmData?.client_account_status) {
			setClientAccountStatus(rmData?.client_account_status as BackendTypes.OverviewAccountStatus);
		}

		setPotentialStatuses(
			getAvailableStatuses({
				status: rmData?.client_account_status || 'UNDEFINED',
				verificationStatus: reviewState.state,
			})
		);
		setLabel(getLabel(rmData?.client_account_status as string, { kind: 'accountStatus' }));
	}, [rmData?.client_account_status, reviewState.state]);

	const onConfirm = async (data: Record<string, any>) => {
		statusModal.close();

		// TODO: Values['COMMON__RM_FEEDBACK']
		const result: any = {
			...rmData,
			...data,
		};

		if (tempStatus) {
			result.client_account_status = tempStatus;
		}

		await accountDataMutation.mutateAsync(result);
		setTempStatus(null);
	};

	const badgeProps = {
		content: label,
		disabled: potentialStatuses.length < 1,
		color: accountStatusBadgeColors[clientAccountStatus || 'UNDEFINED'] as TFBadgeColors,
	};

	return (
		<>
			{clientAccountStatus ? (
				potentialStatuses.length < 1 || !canEditAccountData ? (
					<AccountStatusBadge {...badgeProps} />
				) : (
					<>
						<Menu styles={{ item: { padding: '.25rem 1rem' } }}>
							<Menu.Target>
								<Box>
									<AccountStatusBadge {...badgeProps} />
								</Box>
							</Menu.Target>
							<Menu.Dropdown>
								{potentialStatuses.map((status) => (
									<Menu.Item
										key={status}
										onClick={() => {
											setTempStatus(status);
											const modalData = getModalData({
												accountStatus: clientAccountStatus,
												newAccountStatus: status,
												verificationStatus: data?.account.reviewStates?.find(
													(r) => r.connectionKind === 'APPLICANT'
												)?.state,
											});
											setModalData(modalData);
											statusModal.open();
										}}
									>
										<TFBadge
											text={getLabel(status, { kind: 'accountStatus' })}
											color={accountStatusBadgeColors[status] as TFBadgeColors}
											isBackground={false}
										/>
									</Menu.Item>
								))}
							</Menu.Dropdown>
						</Menu>

						<Modal
							size={600}
							opened={statusModal.isOpen}
							onClose={statusModal.close}
							title={modalData?.title}
						>
							<AccountStatusModal
								// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
								initialValues={rmData}
								modalData={modalData as ModalProps}
								onConfirm={onConfirm}
								onCancel={() => {
									setTempStatus(null);
									statusModal.close();
								}}
							/>
						</Modal>
					</>
				)
			) : null}
		</>
	);
};
