import { useState } from 'react';

import { ActionIcon, Box, Button, createStyles, Drawer, Icon, Title } from '@tf/ui';

import { MonitoringCardInfoList } from '@/components/monitoring/MonitoringCard/MonitoringCardInfo/index';

const useStyles = createStyles(({ colors }) => ({
	header: {
		display: 'flex',
		alignItems: 'center',
		padding: '1rem',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		borderBottomColor: colors.light[2],
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 500,
		flex: 1,
	},
	content: {
		padding: '1rem',
	},
}));

export const MonitoringCardInfoButton = () => {
	const { classes } = useStyles();
	const [isOpen, setIsOpen] = useState(false);

	const toggleDrawer = () => setIsOpen(!isOpen);

	return (
		<>
			<Button
				variant="light"
				size="sm"
				leftIcon={<Icon.IconListSearch size={16} />}
				onClick={toggleDrawer}
			>
				Search Info
			</Button>
			<Drawer
				overlayProps={{ opacity: 0.1 }}
				position="right"
				opened={isOpen}
				onClose={toggleDrawer}
				size={350}
				lockScroll={false}
				withCloseButton={false}
				styles={{ body: { padding: 0 } }}
			>
				<Box className={classes.header}>
					<Title className={classes.title}>Search info</Title>
					<ActionIcon>
						<Icon.IconX size={18} onClick={toggleDrawer} />
					</ActionIcon>
				</Box>
				<Box className={classes.content}>
					<MonitoringCardInfoList />
				</Box>
			</Drawer>
		</>
	);
};
