import type { BackendTypes } from "@tf/api";

import { TFBadgeColors } from "@/components/shared";

export const accountStatusBadgeColors: Record<BackendTypes.OverviewAccountStatus | 'UNDEFINED', string> = {
	UNDEFINED: TFBadgeColors.DIMMED,
	PROSPECT: TFBadgeColors.BLUE,
	ACTIVE: TFBadgeColors.GREEN,
	REJECTED: TFBadgeColors.RED,
	SUSPENDED: TFBadgeColors.ORANGE,
	CLOSED: TFBadgeColors.RED,
};