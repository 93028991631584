import type { BackendTypes } from '@tf/api';

import { ProspectActiveConfirmationText } from '@/components/accounts/AccountToolbar/AccountStatus/content';
import type { ModalProps } from '@/components/accounts/AccountToolbar/AccountStatus/types/types';
import type { PrimitiveField } from '@/components/shared';
import { FieldName } from '@/components/shared';

const fields: Record<string, PrimitiveField> = {
	onboardingDate: {
		name: 'onboarding_date',
		component: FieldName.DateField,
		label: 'Onboarding date',
	},
	accountId: {
		name: 'account_id',
		component: FieldName.TextField,
		label: 'Account ID',
	},
	closingDate: {
		name: 'closing_date',
		component: FieldName.DateField,
		label: 'Closing date',
	},
};

export const getModalData = ({
	accountStatus,
	newAccountStatus,
	verificationStatus,
}: {
	accountStatus: BackendTypes.OverviewAccountStatus | 'UNDEFINED';
	newAccountStatus: BackendTypes.OverviewAccountStatus;
	verificationStatus?: BackendTypes.ReviewState;
}): ModalProps => {
	if (
		accountStatus === 'PROSPECT' &&
		newAccountStatus === 'ACTIVE' &&
		verificationStatus === 'VERIFIED'
	) {
		return {
			title: "Please confirm you'd like to activate this account?",
			text: 'Once activated, the profile will be monitored against an AML risk database.',
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			additionalFields: [fields.accountId, fields.onboardingDate],
			confirmButtonLabel: 'Activate account',
		};
	}

	if (accountStatus === 'PROSPECT' && newAccountStatus === 'ACTIVE') {
		const content = <ProspectActiveConfirmationText />;
		return {
			title: "Confirm you'd like to activate this account?",
			content: content,
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			additionalFields: [fields.accountId, fields.onboardingDate],
			confirmButtonLabel: 'Activate anyway',
		};
	}

	if (accountStatus === 'PROSPECT' && newAccountStatus === 'REJECTED') {
		return {
			title: "Please confirm you'd like to reject this application?",
			text: 'Once rejected, the account cannot be reactivated.',
			confirmButtonLabel: 'Reject account',
		};
	}

	if (accountStatus === 'ACTIVE' && newAccountStatus === 'SUSPENDED') {
		return {
			title: "Confirm you'd like to suspend this account?",
			text: 'Please note, once suspended, you will always be able to reactivate the account and the profile will continue to be monitored. If you prefer to permanently deactivate this account, please use the "Close" option instead.',
			confirmButtonLabel: 'Suspend account',
		};
	}

	if (accountStatus === 'SUSPENDED' && newAccountStatus === 'ACTIVE') {
		return {
			title: "Please confirm you'd like to reactivate this account?",
			text: 'Once activated, the profile will be monitored against an AML risk database.',
			confirmButtonLabel: 'Reactivate account',
		};
	}

	if ((accountStatus === 'ACTIVE' || accountStatus === 'SUSPENDED') && newAccountStatus === 'CLOSED') {
		return {
			title: "Please confirm you'd like to close this account?",
			text: 'Once the account is closed, you will not be able to reactivate it, and the profile will no longer be monitored',
			// @ts-expect-error FIXME TS error is suppressed for migration, fix it later
			additionalFields: [fields.closingDate],
			confirmButtonLabel: 'Close account',
		};
	}

	return {
		title: 'Please confirm',
		text: 'Please confirm',
		confirmButtonLabel: 'Confirm',
	};
};
