import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { BackendClient, type BackendTypes } from '@tf/api';
import { T } from '@tf/utils';

import { useOverviewData } from '@/core/api/overview';
import { getAccountQuery } from '@/core/queries';

const accountQueryOptions = (accountId: number) => {
	return queryOptions({
		queryKey: ['accounts', accountId],
		queryFn: () => getAccountQuery(accountId),
		staleTime: 20 * 1000,
	});
};

export const useAccount = (accountId: number) => {
	return useQuery(accountQueryOptions(accountId));
};

export const useCreateAccountMutation = () => {
	const { data } = useOverviewData();
	const tenantNodeId = data?.tenantNodeId;
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			accountKind,
			entitySelfSegmentKind,
			entitySelfSegmentData,
		}: {
			accountKind: BackendTypes.EntityKind;
			entitySelfSegmentKind: BackendTypes.SegmentKind;
			entitySelfSegmentData: Record<string, any>;
		}): Promise<BackendTypes.EntityConnectNewData> => {
			T.assertNotNullish(tenantNodeId, 'overview not loaded');

			const res = await BackendClient.getInstance().entityConnectNew(
				tenantNodeId,
				'ACCOUNT',
				accountKind,
				{
					listEntitySegments: [
						{
							// @ts-expect-error No need for graphId here
							segmentIdentity: {
								segmentKind: entitySelfSegmentKind,
							},
							segment: entitySelfSegmentData,
						},
						{
							// @ts-expect-error No need for graphId here
							segmentIdentity: {
								segmentKind: 'COMMON__RM_FEEDBACK',
							},
							segment: { client_account_status: 'PROSPECT' },
						},
					],
					listConnectionSegments: [],
				}
			);

			return res.json();
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ['overviewAccounts'] });
		},
	});
};
